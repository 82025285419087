import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Field from 'components/shared/field';
import FormButton from 'components/shared/form-button';

import CircleBot from './images/circle-b.inline.svg';
import CircleTop from './images/circle-t.inline.svg';

const BUTTON_SUCCESS_TIMEOUT_MS = 1.5 * 1000;

const validationSchema = yup.object().shape({
  email: yup.string().email('Please provide a valid email').required('Email is a required field'),
  name: yup.string('Please, provide a valid name'),
  message: yup.string('Please, provide a meaningful message'),
});

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const Form = ({ className: additionalClassName, formName, buttonText, formId }) => {
  const classNamesForm = classNames('relative z-10 mt-4', additionalClassName);

  const classNamesContentWrapper = classNames(
    'relative flex flex-col space-y-6 items-center w-full '
  );

  const classNamesField = classNames('!bg-white rounded-lg h-[68px] !p-6');

  const classNamesTextArea = classNames('!bg-white rounded-lg h-[132px] !px-6 !py-5');

  const classNamesFieldError = classNames('p-0.5');

  const classNamesButton = classNames(
    'self-end justify-center mt-8 rounded-8xl items-center w-full h-[68px] !px-8 sm:w-auto'
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const [buttonState, setButtonState] = useState('default');

  const onSubmit = (values) => {
    setButtonState('loading');
    fetch(`${process.env.GATSBY_WP_URL}/wp-json/gf/v2/forms/${formId}/submissions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        input_1: values.name,
        input_3: values.message,
        input_4: values.email,
      }),
    })
      .then(() => {
        reset({ email: '' });
        setButtonState('success');
        setTimeout(() => {
          setButtonState('default');
        }, BUTTON_SUCCESS_TIMEOUT_MS);
      })
      .catch((error) => {
        setButtonState('default');
        throw new Error(error);
      });
  };

  return (
    <div className="relative w-full bg-gray-1 rounded-4xl py-10 md:px-12 px-5 overflow-hidden">
      <form
        className={classNamesForm}
        data-netlify="true"
        name={formName}
        method="post"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input name="form-name" type="hidden" value={formName} />
        {/* hidden field to filter out bot users
            https://docs.netlify.com/forms/spam-filters/ */}
        <input name="bot-field" type="hidden" />
        <div className={classNamesContentWrapper}>
          <Field
            className={classNamesField}
            classNameError={classNamesFieldError}
            type="input"
            size="xs"
            placeholder="Your name..."
            error={errors?.name?.message}
            label="Name"
            id="Name"
            {...register('name')}
          />
          <Field
            className={classNamesField}
            classNameError={classNamesFieldError}
            type="email"
            size="xs"
            placeholder="Your business email..."
            error={errors?.email?.message}
            label="Email"
            id="Email"
            {...register('email')}
          />
          <Field
            className={classNamesTextArea}
            classNameError={classNamesFieldError}
            type="textarea"
            size="xs"
            placeholder="Tell us how we can help..."
            error={errors?.message?.message}
            label="Message"
            id="Message"
            {...register('message')}
          />
          <FormButton className={classNamesButton} size="md" state={buttonState} theme="blue">
            {buttonText}
          </FormButton>
        </div>
      </form>
      <CircleTop className="absolute z-0 xs:right-0 xs:top-0 -right-12 -top-12" />
      <CircleBot className="absolute z-0 xs:left-10 xs:bottom-0 left-5 -bottom-5" />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  formId: PropTypes.number.isRequired,
};

Form.defaultProps = {
  className: null,
  buttonText: 'Submit',
};

export default Form;
