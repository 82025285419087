import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import Form from './form';

const CONTACT_US_FORM_ID = 7;

const Hero = ({ title, description, formButtonText }) => (
  <section className="pb-[72px] header-offset">
    <Container>
      <div className="relative max-w-[1220px] mt-8 mx-auto flex flex-col">
        <div className="col-start-1 col-end-2 pt-8 text-center">
          <Heading className="tracking-wide" tag="h1" size="lg" fontWeight="extra">
            {title}
          </Heading>
          <p className="mt-5 text-xl">{description}</p>
        </div>

        <div className="mt-8 md:mt-14 mx-auto max-w-[680px] w-full">
          <Form
            formId={CONTACT_US_FORM_ID}
            formName="contact-us-form"
            buttonText={formButtonText}
          />
        </div>
      </div>
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  formButtonText: PropTypes.string,
};

Hero.defaultProps = {
  formButtonText: '',
};

export default Hero;
