import { graphql } from 'gatsby';
import React from 'react';

import Hero from 'components/pages/contact-us/hero';
import BookADemo from 'components/shared/book-a-demo';
import MainLayout from 'layouts/main';

const ContactUs = ({
  data: {
    wpPage: { seo, acf },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <Hero {...acf.contactUsHero} />
    <BookADemo />
  </MainLayout>
);

export default ContactUs;

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        contactUsHero {
          title
          description
          formButtonText
        }
      }
      ...wpPageSeo
    }
  }
`;
